import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import { TextField, Button, OutlinedInput, InputLabel, CircularProgress, Snackbar } from '@mui/material'
import { green } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {instance } from '../../axiosConfig'
import NavBar from '../Navbar/Navbar';
import './Home.css'

const Home=(props)=>{
    const navigate = useNavigate()

    const[form, setForm] = useState({username:'', password:''})
    const [showPassword, setShowPassword] = React.useState(false);

    const [loading, setLoading] = useState(false)
    const [snackOpen, setSnackOpen] = useState({alert:false, message:''})

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword =()=>{}

    useEffect(()=>{
        let user = localStorage.getItem('userId')
        if (user !== null){
            navigate('/console')
        }

    },[])

    const formSubmit = async (e)=>{
        e.preventDefault()

        try{
            const res = await instance.post('/login', form)
            console.log(res.data)

            setLoading(true)
            if(res.data.status === 200){
                localStorage.setItem('userId', res.data.vendor['_id'])
                localStorage.setItem('vendorName', res.data.vendor['vendor_name'])
                
                setLoading(false)
                setSnackOpen({...snackOpen, alert:true, message:res.data.message})
                
                navigate('/console')
            }else{
                setSnackOpen({...snackOpen, alert:true, message:res.data.message})
                setLoading(false)
            }
        }catch(ex){
            console.log(ex.response)
            setSnackOpen({...snackOpen, alert:true, message:ex.response.data.message})
        }
    }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackOpen({...snackOpen, alert:false});
    };

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return(
        <div className="bg-sec-1">
            <NavBar/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5">
                    <img style={{opacity:'0'}} className="login-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/>
                        <div className="card" style={{boxShadow:'6px 6px 7px white'}}>
                            <div className="card-body">
                                <div>
                                    <img className="login-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical_n7jysw.png" alt=""/>
                                </div>
                                <form className='' onSubmit={formSubmit} action="" method="post">
                                        <div className='my-3'>
                                            <FormControl sx={{ m: 1, width: '35ch' }}>
                                                <TextField id="outlined-basic" label="Email" variant="outlined" placeholder='Email' 
                                                value={form.username} 
                                                required 
                                                onChange={(e)=>{setForm({...form, username: e.target.value})}}
                                                />
                                            </FormControl>
                                        </div>

                                        <div className='my-3'>
                                            <FormControl sx={{ m: 1, width: '35ch' }} variant="outlined">    
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        }
                                                        label="Password"
                                                        value={form.password}
                                                        onChange={(e)=>{setForm({...form, password: e.target.value})}}
                                                    />
                                            </FormControl>
                                        </div>

                                        <div>
                                            <FormControl sx={{ m: 1, width:'35ch'}}>
                                                <input className='form-control' style={{borderColor:'#220B21'}} type="submit" />
                                            </FormControl>
                                        </div>

                                        {loading ? 
                                            <div> 
                                                <CircularProgress
                                                    size={15}
                                                    sx={{
                                                    color: green[500],
                                                    zIndex: 1,
                                                    }}
                                                /> 
                                        </div>:null}

                                        {snackOpen.alert ? <Snackbar open={snackOpen.alert} autoHideDuration={8000} onClose={handleSnackBarClose} message={snackOpen.message} action={action} /> : null}
                                </form>

                                <a className='mt-4' href="#">Use Single Sign-On</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;