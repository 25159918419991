import React, {useEffect, useState} from "react";
import { Container, Grid, Typography, MenuItem, Select,FormControl, InputLabel } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { BarChart  } from '@mui/x-charts/BarChart';
import { useSearchParams } from 'react-router-dom';
import DateRangePickerComponent from "../../DateAndDayPicker/DateRange";
import DaysPickerComponent from "../../DateAndDayPicker/DaysPickerComponent";
import { instance } from '../../../axiosConfig'
import './Monitoring.css'

const Monitoring = ({ contentId, contentName }) =>{
    const [views, setViews] = useState()
    const [metrics, setMetrics] = useState()
    const [overall, setOverall] = useState()

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDays, setSelectedDays] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [filterType, setFilterType] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get('start');
    const endDate = searchParams.get('end');
    const days = searchParams.get('days');
    const filter = searchParams.get('filterType');

    function updateQueryParams(start, end, filterType) {
        setSearchParams({ start,  end,  filterType });
    }

    useEffect(()=>{
        getVideoViews()
        getVideoMetrics()
        getOverallVideoMetrics()

        if (selectedDate) {
            updateQueryParams(dateToSeconds(selectedDate.startDate), dateToSeconds(selectedDate.endDate), filterType);
        }

        if(selectedDays > 0){
            setSearchParams({ days: selectedDays })
        }
    },[selectedDate, selectedDays, filterType])

    const getVideoViews= async()=>{
        let res
        if(filter == "absolute" || filter == "relative"){
            if(startDate > 0 && endDate > 0){
                res = await instance.get(`/${contentId}/metrics/views?start=${startDate}&end=${endDate}&filterType=${filter}`)
            }else if(days > 0){
                res = await instance.get(`/${contentId}/metrics/views?days=${days}`)
            }else{
                res = await instance.get(`/${contentId}/metrics/views`)
            }
        }else{
            res = await instance.get(`/${contentId}/metrics/views`)
        }
        
        if(res.data.status === 200){
            //console.log(res.data)
            setViews(res.data.views)
        }
    }

    const getVideoMetrics= async()=>{
        const res = await instance.get(`/${contentId}/metrics/insights`)
        if(res.data.status === 200){
            // console.log(res.data)
            setMetrics(res.data.metrics)
        }
    }

    const getOverallVideoMetrics= async()=>{
        const res = await instance.get(`/${contentId}/metrics/overall`)
        if(res.data.status === 200){
            // console.log(res.data)
            setOverall(res.data.metrics)
        }
    }

    const convertLengthMilliToTime = (length)=>{
		const totalMinutes = Math.floor(length / 60000); // 1 minute = 60,000 milliseconds
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		if(!isNaN(hours) && !isNaN(minutes)){
			return { hours, minutes };
		}
		return {hours:0, minutes:0}
	}

    const getViewStartDate=(time)=>{
        //toString()    Example output: "Sun Jun 26 2024 02:12:58 GMT+0000 (Coordinated Universal Time)"
        //toISOString() Example output: "2024-06-26T02:12:58.000Z"
        //toUTCString() Example output: "Sun, 26 Jun 2024 02:12:58 GMT"
        //LocaleString()Example output: "6/26/2024, 2:12:58 AM"

        //Convert Timestamp to milliseconds
        let startDate = new Date(time * 1000).toLocaleString()
        return startDate;
    }

    const getViewEndDate=(time)=>{
        let endDate = new Date(time * 1000).toLocaleString()
        return endDate
    }
    
    /**
     * 
     * @param {Date} Object - Date Object 
     */
    const dateToSeconds = (Object)=>{
        const seconds = Math.floor(Object.getTime() / 1000);
        console.log(seconds); // Outputs the number of seconds since the Unix epoch
        return seconds
    }
    return (
        <>
            <Container sx={{ padding: 0 }}>
                <Typography variant="h6" marginBottom={3}>
                    <Grid container spacing={1} alignItems="start">
                        <Grid item xs={4} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-type-select-label">Filter</InputLabel>
                                <Select labelId="filter-type-select-label" sx={{height:'50px'}} value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value="absolute">Absolute</MenuItem>
                                    <MenuItem value="relative">Relative</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            {filterType === "absolute" ? (
                            <DateRangePickerComponent setSelectedDate={setSelectedDate} />
                            ) : filterType === "relative" ? (
                            <DaysPickerComponent setSelectedDays={setSelectedDays} />
                            ) : null}
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="country-select-label">By Country</InputLabel>
                                <Select labelId="country-select-label" sx={{height:'50px'}} value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value="Nigeria">Nigeria</MenuItem>
                                    <MenuItem value="Ghana">Ghana</MenuItem>
                                    <MenuItem value="South Africa">South Africa</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Typography>
            </Container>

            {/* Views multiplied by 2 for studios with a load factor of 2 */}
            <Grid style={{marginLeft:'-432px'}} container justifyContent="start" marginTop={0} rowSpacing={0} columnSpacing={{ xs: 10, sm: 20, md: 50 }}>
                <Grid item xs={2} sm={3} md={3} >
                    <div className='chart-skeleton' style={{padding:'10px'}}>
                        <div style={{fontWeight:'bold'}}>Time period (30 days)</div>
                        <span>{`${getViewStartDate(views && views.timeframe[0])} - ${getViewEndDate(views && views.timeframe[1])}`}</span>
                        <div> <hr /></div>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['2024', '2025', '2026','2027'], label:'Years'}]} 
                            yAxis={[{ label:'Views'}]}
                            series={[
                                { id: contentName, label: contentName, data: [views && views.total_row_count, 0, 0] }, 
                            ]}
                            width={330}
                            height={200}
                        />
                    </div>
                </Grid>

                <Grid item xs={2} sm={3} md={3} >
                        <div className='chart-skeleton' style={{padding:'10px'}}>
                        <div style={{fontWeight:'bold'}}>Time period (30 days)</div>
                        <span>{`${getViewStartDate(metrics && metrics.timeframe[0])} - ${getViewEndDate(metrics && metrics.timeframe[1])}`}</span>
                        <div> <hr /></div>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['2024', '2025', '2026','2027'], label:'Viewer experience score(%)'}]} 
                            yAxis={[{ label: metrics && metrics.data[2].name}]}
                            series={[
                                { id: contentName, label: contentName, data: [metrics && metrics.data[2].value * 100] }, 
                            ]}
                            width={330}
                            height={200}
                        />
                    </div>
                </Grid>

                <Grid item xs={2} sm={3} md={3} >
                    <div className='chart-skeleton-pie' style={{padding:'10px'}}>
                        <div style={{fontWeight:'bold'}}>Time period (30 days)</div>
                        <span>{`${getViewStartDate(metrics && metrics.timeframe[0])} - ${getViewEndDate(metrics && metrics.timeframe[1])}`}</span>
                        <div> <hr /></div>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { 
                                            id: 0,
                                            value: metrics && metrics.data[1].items[1].value, // Playing Time (total) (ms)
                                            label: metrics && metrics.data[1].items[1].name,
                                            color:'lightgreen'
                                        },
                                        { 
                                            id: 1, 
                                            value:  metrics && metrics.data[1].items[2].value, //Playing Time (average) use hours and minutes (ms)
                                            label:  metrics && metrics.data[1].items[2].name,
                                            color:'pink'
                                        },
                                        { 
                                            id: 2, 
                                            value: metrics && metrics.data[1].items[3].value, //Playing Time (median) (ms)
                                            label: metrics && metrics.data[1].items[3].name,
                                            color:'#15C690'
                                        },
                                    ],
                                    arcLabel: (item) => `${convertLengthMilliToTime(item.value).hours}hr,${convertLengthMilliToTime(item.value).minutes}min`,
                                    arcLabelMinAngle: 10,
                                    innerRadius: 50,
                                    paddingAngle: 3,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                },
                            ]}
                            width={330}
                            height={200}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                  fill: 'black',
                                  fontSize:'12px',
                                },
                                ["& .MuiChartsLegend-series text"]: { 
                                    fontSize: "12px !important",
                                    fontWeight:'700 !important',
                                    margin:'100px!important' ,
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container justifyContent="start" marginTop={5} rowSpacing={0} columnSpacing={{ xs: 10, sm: 20, md: 50 }}>
                <Grid item xs={2} sm={3} md={3} >
                    <div className='chart-skeleton-pie' style={{padding:'10px', marginLeft:'0px'}}>
                        <div style={{fontWeight:'bold'}}>Time period (30 days)</div>
                        <span>{`${getViewStartDate(metrics && metrics.timeframe[0])} - ${getViewEndDate(metrics && metrics.timeframe[1])}`}</span>
                        <div> <hr /></div>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { 
                                            id: 0, 
                                            value: metrics && metrics.data[1].items[0].value, //Unique viewer (number)
                                            label: metrics && metrics.data[1].items[0].name,
                                            color:'red'
                                        },
                                    ],
                                    arcLabel: (item) =>  item.value,
                                    arcLabelMinAngle: 45,
                                    innerRadius: 30,
                                    paddingAngle: 2,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                },
                            ]}
                            width={330}
                            height={200}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                    fontSize:'12px',
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Monitoring;