import React, {useEffect, useState} from "react";


const Legal = ({  }) =>{
    useEffect(()=>{
      
    },[])


    return (
        <>
            <p>For any legal questions please reach out to <a target='_blank' href="mailto:solomon.oshinubi@afrowatch.co">solomon.oshinubi@afrowatch.co</a></p>
        </>
    )
}

export default Legal;