import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import {Box, Button, FormControl, MenuItem, Select, InputLabel, TextField} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import { SubtitleLanguages} from '../../../constants'
import {instance} from '../../../axiosConfig'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Subtitles = ({ subtitles, videoPath, contentId }) =>{
    const [selectedFile, setSelectedFile] = useState({video:null, subtitles:[], images:null, headerImages:null, size:''});

    const [loading, setLoading] = useState({subProgress:false, subSuccess:false})
    const [snackOpen, setSnackOpen] = useState({alert:false, message:'', formValid:true})
    const [vendorSubtitles, setVendorSubtitles] = useState([]);

    const [existingSubtitles, setExisting] = useState([]);

    useEffect(()=>{
      console.log(subtitles)
      checkExisitingSubtitles()
    },[])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackOpen({...snackOpen, alert:false});
    };

    const onSubmit= async (e)=>{
        e.preventDefault()

        const isSuccessful = await uploadSubtitle()
        if(isSuccessful){
            updateVendorTable()
        }
    }

    const updateVendorTable=async()=>{
        try {
            const res = await instance.put(`/update-subtitles?contentId=${contentId}&vendorId=${localStorage.getItem('userId')}&vendorName=${localStorage.getItem('vendorName')}`, {subtitles:vendorSubtitles})
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const uploadSubtitle= async ()=>{
        const formData = new FormData();
        selectedFile.subtitles.forEach(file => {
            formData.append('video_subtitles', file);
        });
        
        let success
        try{
            const res = await instance.post(`/upload-subtitles-monitoring?path=${videoPath}&contentId=${contentId}&languages[]=${vendorSubtitles}`, formData)
            console.log(res.data)

            if(res.data.status == 200){
                success = true
                setLoading({...loading, subProgress:false, subSuccess:true})
                setSnackOpen({...snackOpen, alert:true, message:res.data.message})
            }else{
                success = false
                setSnackOpen({...snackOpen, alert:false, message:`subtitles:${res.data.message}`})
            }
            return success
        }catch(e){
            console.error(e)
        }
    }

    const handleSubtitleFileInputChange = (event) => {
        const file = event.target.files;
        const pattern = /^(en|fr|yb|igb|xh)\.[a-zA-Z0-9_-]+\.(vtt)$/i; //(srt|vtt)

        if (file.length > 0) {
            setSelectedFile({...selectedFile, subtitles:[...selectedFile.subtitles, ...file], size:file.size})
        }
    };

    const checkExisitingSubtitles=()=>{
        try{
            const labels = subtitles.map((s)=> {
                const found  =  SubtitleLanguages.find((sub)=> sub.language === s )
                return found ? found.label : null;
            }).filter(label => label !== null);

            // console.log(labels)
            setExisting(labels)
        }catch(e){
            console.log(e)
        }
    }

    const formatSubtitles = (subtitles) => {
        const length = subtitles.length;
        if (length === 0) return '';
        if (length === 1) return subtitles[0];
        if (length === 2) return subtitles.join(' and ');
        return `${subtitles.slice(0, -1).join(', ')}, and ${subtitles[length - 1]}`;
    };

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <>
            {snackOpen.alert ? <Snackbar open={snackOpen.alert} autoHideDuration={8000} onClose={handleSnackBarClose} message={snackOpen.message} action={action} /> : null}

            <p>Your existing subtitles are:{formatSubtitles(existingSubtitles)}</p>

            <form onSubmit={onSubmit} action="" method='POST'>
                <Box sx={{ p: 2, marginY:2, border: '1px dashed black' }}>
                    <p>Subtitles your <b>.vtt</b> file <span style={{fontSize:'12px', fontWeight:'bold', color:'red'}}>(Max 3MB)</span></p>
                    <span>Please follow the pattern. (<Link target={'_blank'} to='/help'>language</Link>.contentname.extension). for your file name</span>
                    <p>Example</p>
                    <p>English would be <span style={{textDecoration:'underline'}}><b>(en.Lionking.vtt)</b></span> Or Yoruba would be <span style={{textDecoration:'underline'}}><b>(yb.Lionking.vtt)</b></span></p>
                    {
                        SubtitleLanguages.map((lang, idx) =>{
                            return <div className='d-flex'>
                                <TextField key={lang.language}
                                    className='my-2 text-content-width'
                                    id="outlined-basic"
                                    select
                                    label="Subtitle language"
                                    value={vendorSubtitles[idx]}
                                    onChange={(e)=>{
                                        const languages = [...vendorSubtitles]
                                        if (e.target.value === "") {
                                            languages.splice(idx, 1)
                                        } else {
                                            languages[idx] = e.target.value;
                                        }
                                        setVendorSubtitles(languages)
                                    }}
                                    variant="outlined">

                                    <MenuItem value="">Clear</MenuItem>
                                    {SubtitleLanguages.map(lang => (
                                        <MenuItem key={lang.language} value={lang.language}>
                                            {lang.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <input onChange={handleSubtitleFileInputChange} className="file_input mt-4 ms-2" 
                                    type="file" encType="multipart/form-data" 
                                    name="video_subtitles" 
                                    id={`file-${lang.label}`}
                                    data-multiple-caption="{count} files selected" 
                                    multiple 
                                />
                            </div>
                        })
                    }
                    {loading.subProgress ? 
                    <div> 
                        <CircularProgress
                            size={15}
                            sx={{
                            color: green[500],
                            zIndex: 1,
                            }}
                        /> 
                    </div>:null}
                    {loading.subSuccess ? <CheckIcon color='success' fontSize='medium'/> : null}
                    <input className={`form-control mt-4`} style={{borderColor:'#220B21'}} type="submit" />
                </Box>
            </form>
        </>
    )
}

export default Subtitles;