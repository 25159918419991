import React, {useState, useEffect} from 'react'
import { Link} from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NavSide from '../Dashboard/NavSide'
import { instance } from '../../axiosConfig'
import './Admin.css'

const AdminContentView=()=>{
    const [vendors, setVendors] = useState()

    useEffect(()=>{
        getAllContentByVendors()
    },[])

    const getAllContentByVendors= async ()=>{
        const res = await instance.get('/admin/content')
        if(res.data.status == 200){
            setVendors(res.data.data)
        }
    }

    const convertLengthSecondsToTime = (length)=>{
		const totalMinutes = Math.floor(length / 60); // 1 minute = 60seconds
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		if(!isNaN(hours) && !isNaN(minutes)){
			return { hours, minutes };
		}
		return {hours:0, minutes:0, seconds:length}
	}

    //seconds
    const getTotalLength = (vendorData) => vendorData.map((content) => content.length).reduce((a, b) => a + b, 0);

    return(
        <>
            <NavSide/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mt-5">Content</h1>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650, background:'#2D132C', color:'white' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/* td */}
                                        <TableCell style={{color:'white'}} sx={{fontWeight:'bold'}}>Vendor</TableCell>
                                        <TableCell style={{color:'white'}} sx={{fontWeight:'bold'}} >Content Count</TableCell>
                                        <TableCell style={{color:'white'}} sx={{fontWeight:'bold'}} >Total Content Time</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {
                                    vendors ? Object.keys(vendors).map((vendor, index)=>{
                                        return  <TableRow
                                            key={vendor}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            <Link 
                                                style={{color:'white'}} 
                                                to={`/admin/content/${vendor}`}
                                                state={{ content: vendors[vendor], role:'admin' }}
                                            >
                                                {vendor}
                                            </Link>
                                        </TableCell>
                                        <TableCell style={{color:'white'}} component="th" scope="row">
                                            {vendors[vendor].length}
                                        </TableCell>
                                        <TableCell style={{color:'white'}} component="th" scope="row">
                                            {
                                                vendors[vendor].map((content)=> content.length).length > 0 
                                                ? 
                                                convertLengthSecondsToTime(getTotalLength(vendors[vendor])).hours > 0 ||  convertLengthSecondsToTime(getTotalLength(vendors[vendor])).minutes > 0 ? 
                                                `${convertLengthSecondsToTime(getTotalLength(vendors[vendor])).hours}h 
                                                ${convertLengthSecondsToTime(getTotalLength(vendors[vendor])).minutes}m` 
                                                : `${Math.round(getTotalLength(vendors[vendor]))}s`
                                                : 0
                                            }
                                        </TableCell>
                                    </TableRow>
                                    }) : <>No vendors</>
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdminContentView;