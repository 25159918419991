import React , { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import SearchBar from '../Movies/Search/Search'
import NavSide from '../Dashboard/NavSide'
import Documents from './Documents/Documents';
import VIDEO  from './Video'
import Monitoring from './Monitoring/Monitoring'
import Legal from './Legal/Legal'
import Subtitles from './Subtitles/Subtitles'
import {Container, Grid, Button, Box, Tab, Tabs, Typography} from '@mui/material'
import { instance } from '../../axiosConfig';


const MovieDetails = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [value, setValue] = React.useState(0);

    const state = location.state || {};

    const movieName = state.movieName || 'Content Name';
    const description = state.description || 'No Description Available';
    const year = state.year || 'N/A';
    const licensedDate = state.licensedDate || 'N/A';
    const genre = state.genre || 'N/A';

    const [storedState, setStoredState] = useState({
        id: state.id,
        movieName: movieName,
        licensedDate: licensedDate,
        description: description,
        year: year,
        genre: genre
    });

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    const onHelpPressed=()=>{
        navigate('/help')
    }

    useEffect(() => {
        if (location.state) {
            setStoredState({
                id: location.state.id,
                movieName: location.state.movieName,
                licensedDate: location.state.licensedDate,
                description: location.state.description,
                year: location.state.year,
                genre: location.state.genre
            });
        }
    }, [location.state]);

    
    const [hlsUrl, setHlsUrl] = useState('');
    const [videoType, setVideoType] = useState('');
    const playerRef = React.useRef(null);

    const getContentIds = async () => {
        try {
            const response = await instance.get(`/stream/${state.id}/`);
            const { data } = response.data;
            setHlsUrl(`${process.env.REACT_APP_STREAM_URL}/${data.content.hls_stream_path}`);
            setVideoType(data.content.video_content_typ);
        } catch (error) {
            console.error('Error fetching content IDs:', error);
        }
    };

    const [images, setImages] = useState({header:'', card:''});
    const [subtitles, setSubtitles] = useState([]);
    const [content, setContent] = useState({videoPath:'', contentId:''});

    const getContentUrls= async()=>{
        try {
            const response = await instance.get(`/content/${state.id}/`);
            const { data } = response.data;
            setImages({...images, header:data.video_header_image_url, card:data.video_image_url})
            setSubtitles(data.subtitles)
            setContent({...content, videoPath:data.video_path, contentId:data.content_id})
        } catch (error) {
            console.error('Error fetching content', error);
        }
    }

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: hlsUrl ? hlsUrl : '',
            type: videoType ? videoType : ''
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    useEffect(() => {
        getContentIds();
        getContentUrls()
    }, [location.state.id]);

    const formatGenres = (genre) => {
        try{
            const length = genre.length;
            if (length === 0) return '';
            if (length === 1) return genre[0];
            if (length === 2) return genre.join(' and ');
            if (length > 2) return `${genre.slice(0, -1).join(', ')}, and ${genre[length - 1]}`;
        }catch(e){
            console.log(e)
        }
    };

    return(
        <Container maxWidth='lg'>
            <NavSide/>
            <Grid className='mt-5' container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                <Grid item xs={2} md={6}>
                    <div className="text-left" style={{position:'relative', left:'120px'}}>
                        <h1>{storedState.movieName}</h1>
                        <p>Year: {storedState.year}</p>
                        <p>Description: {storedState.description}</p>
                        <p>Genre: {formatGenres(storedState.genre) || storedState.genre }</p>
                        <p>License start date: {storedState.licensedDate}</p>
                    </div>
                </Grid>

                <Grid item xs={2} md={6}>
                    <span>
                        <img width={150} src={images.card} alt="content_image" style={{borderRadius:'10px'}} />
                    </span>
                </Grid>
            </Grid>

            <Grid className='justify-content-center mt-4' container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 15 }} >
                <Grid item xs={2} md={10}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            {/* <Tab label="LICENSE" {...a11yProps(0)} />
                            <Tab label="BROKER" {...a11yProps(1)} /> */}
                            <Tab label="MONITORING" {...a11yProps(0)} />
                            <Tab label="CONTACT" {...a11yProps(1)} />
                            <Tab label="DOCUMENTS" {...a11yProps(2)} />
                            <Tab label="SALES" {...a11yProps(3)} />
                            <Tab label="LEGAL" {...a11yProps(4)} />
                            <Tab label="HELP" {...a11yProps(5)} />
                            <Tab label="VIDEO" {...a11yProps(6)} />
                            <Tab label="SUBTITLES" {...a11yProps(7)} />
                        </Tabs>
                    </Box>
                    {/* <TabPanel value={value} index={0}>
                        LICENSE
                    </TabPanel > */}
                    {/* <TabPanel value={value} index={1}>
                       BROKER
                    </TabPanel > */}
                    <TabPanel value={value} index={0}>
                       <Monitoring contentId={state.id} contentName={movieName}/>
                    </TabPanel >
                    <TabPanel value={value} index={1}>
                       CONTACT
                    </TabPanel >
                    <TabPanel value={value} index={2}>
                        <Documents/>
                    </TabPanel >
                    <TabPanel value={value} index={3}>
                       SALES
                    </TabPanel >
                    <TabPanel value={value} index={4}>
                       <Legal/>
                    </TabPanel >
                    <TabPanel value={value} index={5}>
                       Please use our Live chat or click here  <Button style={{background:'#2D132C', color:'white'}} onClick={onHelpPressed}>Help</Button>
                    </TabPanel >
                    <TabPanel value={value} index={6}>
                        <VIDEO contentId={state.id} videoTitle={state.movieName} options={videoJsOptions} onReady={handlePlayerReady}/>
                    </TabPanel >
                    <TabPanel value={value} index={7}>
                        <Subtitles subtitles={subtitles} videoPath={content.videoPath} contentId={content.contentId}/>
                    </TabPanel >
                </Grid>
            </Grid>
        </Container>
    )

}

export default MovieDetails;