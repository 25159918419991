import React, {useState, useCallback, useEffect, useMemo} from 'react'
import {Box, Button, FormControl, MenuItem, Select, InputLabel, TextField, Checkbox, ListItemText} from '@mui/material';
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router'
import Intercom from '@intercom/messenger-js-sdk';
import { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from '@mui/icons-material/Delete';
import SideNav from './NavSide'
import './Dashboard.css'
import Genre, { Genres, ContentType, VideoExtension, Countries, SubtitleLanguages} from '../../constants'
import SeriesOptions from './SeriesSelect'
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';


const Dashboard = () =>{
    const navigate = useNavigate()
    const axiosInstance = require('../../axiosConfig');
    const days = ['Sun','Mon','Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
    const months = ['January','February','March','April','May','June','July','August','September', 'October','November','December'];
    const date = new Date()

    let day = `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}`
    let time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    let contentId =''
    let streamId = ''

    const [vendor, setVendor] = useState({email:'', vendor_name:'', content_name:'', video_name: '', content_description:'', genre:[],country:'', other:'',content_type:'', video_extension:'', 
    video_path:'', actors:[], animators:[], release_year:'', isSeries:true ,season_number:1 ,date_uploaded:Date.now(), created_by:'', day_uploaded:day, time_uploaded:time, video_length:0, subtitles_language:[]})
    
    const [selectedFile, setSelectedFile] = useState({video:null, subtitles:[], images:null, headerImages:null, size:''});
    const [alert, setAlert] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState({vidProgress:false, vidSuccess:false, subProgress:false, subSuccess:false, imgProgress:false, imgSuccess:false, hdrImgProgress:false, hdrImgSuccess:false})
    
    const steps = ['Content information', 'Content information two', 'Files'];
    const [activeStep, setActiveStep] = useState(0);
    const [snackOpen, setSnackOpen] = useState({alert:false, message:'', formValid:true})

    //Validation
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        
        [vendor.name, vendor.content_name, vendor.content_description, vendor.genre].forEach((item)=>{
            if(item === ''){
                setSnackOpen({...snackOpen, alert:true, formValid:false, message:'A field is empty or missing !'})
                setActiveStep(0)
            }
        })

        if(vendor.content_name.length > 99){
            setSnackOpen({...snackOpen, alert:true, formValid:false, message:'Your content name has to be less than 100 characters'})
            setActiveStep(0)
        }

        if(activeStep === 1){
            if(vendor.actors.length < 1){
                setActiveStep(1)
                setSnackOpen({...snackOpen, alert:true, formValid:false, message:'You havent added actors'})
            }

            [vendor.content_type, vendor.video_extension, vendor.release_year, vendor.video_path].forEach((item)=>{
                if(item === ''){
                    setSnackOpen({...snackOpen, alert:true, formValid:false, message:'Check your input fields'})
                    setActiveStep(1)
                }
            })

            const regex = /^[0-9]+$/
            const check = regex.test(vendor.release_year)
            if(!check){
                setSnackOpen({...snackOpen, alert:true, formValid:false, message:'release year has to be a number. e.g 2024'})
                setActiveStep(1)
            }
        }
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackOpen({...snackOpen, alert:false});
    };

    const [actorVal, setActorVal] = useState("");
    const [animatorVal, setAnimatorVal] = useState("");

    function setActors(event){   
        switch (event.key) {
            case "Enter":
                if(vendor.actors.length < 7){
                    setVendor({...vendor, actors:[...vendor.actors, actorVal.trim()]})
                    setActorVal(""); 
                }else{
                    setSnackOpen({...snackOpen, alert:true, formValid:false, message:'You can only add up to 7 actors'})
                }
            break;
            default:
                return;
        }
    }

    function setAnimators(event){   
        switch (event.key) {
            case "Enter":
                setVendor({...vendor, animators:[...vendor.animators, animatorVal.trim()]})
                setAnimatorVal(""); 
            break;
            default:
                return;
        }
    }

    let userId = ''
    const getVendor = async()=>{
        userId = localStorage.getItem('userId')
        const res =  await axiosInstance.instance.get(`/vendor/${userId}`)

        if(res.data.status === 200){
            console.log(res.data)
            setVendor({...vendor, vendor_name:res.data.vendor.vendor_name})
        }
    }

    const onSubmit = async (e)=>{
        e.preventDefault()

        const params = {
            vendor_id: localStorage.getItem('userId'),
            vendor_name: vendor.vendor_name,
            content_name: vendor.content_name,
            video_name:vendor.video_name,
            content_description:vendor.content_description,
            genre:vendor.genre,
            actors:vendor.actors,
            animators: vendor.animators,
            released:vendor.release_year,
            content_type:vendor.content_type,
            video_extension:vendor.video_extension,
            video_path:vendor.video_path,
            video_length: vendor.video_length,
            day_uploaded:vendor.day_uploaded,
            date_uploaded: vendor.date_uploaded,
            time_uploaded: vendor.time_uploaded,
            subtitles_list: vendor.subtitles_language,
            isSeries:vendor.isSeries,
            country:vendor.country !== Countries[Countries.length -1 ] ? vendor.country : vendor.other,
        }

        const resp = await axiosInstance.instance.post(`/create-vendor`, params)
        console.log(resp.data)

        contentId = resp.data.data.contentId
        streamId = resp.data.data.streamId

        if(resp.data.status == 200){
            let message = resp.data.message
            setAlert(`${message}`)

            // start uploading file
            setLoading({...loading, vidProgress:true, imgProgress:true, subProgress:true, hdrImgProgress:true })
            try{
                uploadFile()
            }catch(ex){
                console.log(ex)
                setAlert(`error uploading the file`)
            }

            setTimeout(()=>window.scrollTo(0,0) , 1000) 
        }else{
            setAlert(resp.data.message)
        }

    
    }

    useEffect(() => {
        let user = localStorage.getItem('userId')
        if(user === null || user == ""){
            navigate("/")
        }

        getVendor()
    }, []);

    useEffect(()=>{
        if(vendor.content_type === ContentType[1]){
            vendor.isSeries = true
        }else{
            vendor.isSeries =false
        }

        const vendorName = vendor.vendor_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()
        setVendor({...vendor,  
            video_path:vendor.content_type == 'MOVIE' || vendor.content_type == 'SHORTS' ? `${vendorName}/${vendor.content_type}/${vendor.content_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}` : `${vendorName}/${vendor.content_type}/${vendor.content_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}/${vendor.season_number}`,
        })
    },[vendor.content_name, vendor.content_type, vendor.season_number])
    //each time the movie name changes update video name, also each time the content type changes that affects the path
    //The video name and video path have no event to change thier state on their own.

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            //get video duration
            const duration = (file) => {
                const video = document.createElement('video')
                video.preload = 'metadata'
              
                const onLoadedMetaDataPromise = new Promise((resolve) => {
                    video.onloadedmetadata = () => {
                        window.URL.revokeObjectURL(video.src)
                        resolve(video.duration) // seconds
                        video.remove()
                    }
                })
              
                video.src = window.URL.createObjectURL(file)
                return onLoadedMetaDataPromise
            }

            duration(file).then((length)=>{
                console.log(length)

                if(vendor.content_type == ContentType[2] && length > 60){
                    setSnackOpen({...snackOpen, alert:true, formValid:false, message:'A short has to be between 15-60 seconds'})
                    setActiveStep(1)
                    return
                }

                //set video name as uploaded file name
                setVendor({...vendor, video_name:file.name, video_length:length});
                setSelectedFile({...selectedFile, video:file})
            })
        }
    };

    const handleSubtitleFileInputChange = (event) => {
        const file = event.target.files;
        const pattern = /^(en|fr|yb|igb|xh)\.[a-zA-Z0-9_-]+\.(vtt)$/i; //(srt|vtt)

        if (file.length > 0) {
            setSelectedFile({...selectedFile, subtitles:[...selectedFile.subtitles, ...file]})
        }
    };

    const handleImageFileInputChange = (event) => {
        const file = event.target.files[0];
        //< 3MB
        if (file && file.size < 3000000) {
            setSelectedFile({...selectedFile, images:file, size:file.size})
        }else{
            setSnackOpen({...snackOpen, alert:true, formValid:false, message:'Your swipe card image has to be less than 3MB'})
            setActiveStep(2)
        }
    };

    const handleHeaderImageFileInputChange = (event) => {
        const file = event.target.files[0];
        //< 4MB
        if (file && file.size < 4000000) {
            setSelectedFile({...selectedFile, headerImages:file})
        }else{
            setSnackOpen({...snackOpen, alert:true, formValid:false, message:'Your header image has to be less than 4MB'})
            setActiveStep(2)
        }
    };

    const handleGenreChange = (event) => {
        const value = event.target.value;
        setVendor({...vendor, genre:value})
    };

    
    const uploadFile= async ()=>{
        const formData = new FormData();
        formData.append('video_name', selectedFile.video)

        try{
            const res = await axiosInstance.instance.post(`/upload-video?path=${vendor.video_path}&id=${contentId}&streamId=${streamId}&vendorName=${vendor.vendor_name}&vendorId=${localStorage.getItem('userId')}`, formData)
            console.log(res.data)

            if(res.data.status == 200){
                setLoading({...loading, vidProgress:false, vidSuccess:true})
                
                setSnackOpen({...snackOpen, alert:true, message:res.data.message})
                setAlert(`${res.data.message}`)

                window.scrollTo(0,0)
                //after uploading file then upload the remaining
                uploadSubtitle()
                uploadVideoImage()
                uploadHeaderImage()
            }else{
                console.log(res.data)
                setSnackOpen({...snackOpen, alert:false, message:res.data.message})
                setAlert(`${res.data.message}`)
            }
        }catch(e){
            console.log(e, e.response.data.data)
            setError(e.response.data.data)

            setSnackOpen({...snackOpen, alert:true, message:e.response.data.message})
            setAlert(e.message)
            setLoading({...loading, vidProgress:false, imgProgress:false, subProgress:false, hdrImgProgress:false })
        }
    }

    const deleteContent=()=>{
        
    }

    const uploadSubtitle= async ()=>{
        const formData = new FormData();
        //formData.append('video_subtitles', selectedFile.subtitles)
        selectedFile.subtitles.forEach(file => {
            formData.append('video_subtitles', file);
        });
        
        try{
            const res = await axiosInstance.instance.post(`/upload-subtitles?path=${vendor.video_path}&contentId=${contentId}&languages[]=${vendor.subtitles_language}`, formData)
            console.log(res.data)

            if(res.data.status == 200){
                setLoading({...loading, subProgress:false, subSuccess:true})
                setSnackOpen({...snackOpen, alert:true, message:res.data.message})
            }else{
                setSnackOpen({...snackOpen, alert:false, message:`subtitles:${res.data.message}`})
            }
        }catch(e){
            console.error(e)
        }
    }

    const uploadVideoImage= async ()=>{
        const formData = new FormData();
        formData.append('video_image', selectedFile.images)

        try{
            const res = await axiosInstance.instance.post(`/upload-image?path=${vendor.video_path}&vendorId=${localStorage.getItem('userId')}&vendorName=${vendor.vendor_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}&contentId=${contentId}`, formData)
            console.log(res.data)

            if(res.data.status == 200){
                setLoading({...loading, imgProgress:false, imgSuccess:true})
                setSnackOpen({...snackOpen, alert:true, message:res.data.message})
            }else{
                setSnackOpen({...snackOpen, alert:false, message:`video images:${res.data.message}`})
            }
        }catch(e){
            console.error(e)
        }
    }

    const uploadHeaderImage= async ()=>{
        const formData = new FormData();
        formData.append('video_image_header', selectedFile.headerImages)

        try{
            const res = await axiosInstance.instance.post(`/upload-header-image?path=${vendor.video_path}&vendorId=${localStorage.getItem('userId')}&vendorName=${vendor.vendor_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}&contentId=${contentId}`, formData)
            console.log(res.data)

            if(res.data.status == 200){
                setLoading({...loading, hdrImgProgress:false, hdrImgSuccess:true})
                setSnackOpen({...snackOpen, alert:true, message:res.data.message})
            }else{
                setSnackOpen({...snackOpen, alert:false, message:`video header images:${res.data.message}`})
            }
        }catch(e){
            console.error(e)
        }
    }

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    
    let submitBtn = selectedFile.headerImages !== null && selectedFile.images !== null ? 'submit-show' : 'submit-hide'

    Intercom({
        app_id: process.env.REACT_APP_INTERCOM_ID,
        user_id: localStorage.getItem('userId'), 
        name: vendor.vendor_name, 
        email: vendor.vendor_email, 
        created_at: vendor.creation_date, 
    });

    return(
        <div className='bg-Dashboard'>
            <SideNav/>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-md-12">
                        {/* vendor.vendor_name.split('@')[0] */}
                        <h2>Welcome to Afrowatch console {vendor.vendor_name} </h2>
                    </div>

                    {alert.length > 1 ?
                        <div className="col-md-4 justify-content-center mt-3">
                            <p className={`alert ${error && error.length > 0 ? 'alert-danger' :'alert-success'}`} style={{position:'absolute',right:'12px',top:'10px', fontSize:'14px', zIndex:999}}>
                                {alert}
                                {error && error.length > 0 ? <><br/><ErrorOutlineOutlinedIcon/><Link to={'/failing'} style={{color:'red'}}>Failing uploads</Link></>  : null}
                            </p>
                        </div>
                    : null}
                </div>

                {snackOpen.alert ? <Snackbar open={snackOpen.alert} autoHideDuration={8000} onClose={handleSnackBarClose} message={snackOpen.message} action={action} /> : null}

                {snackOpen.formValid ? null : <Snackbar open={snackOpen.alert} autoHideDuration={3000} onClose={handleSnackBarClose} message={snackOpen.message} action={action} />}
                
                <div className="row justify-content-center mt-3">
                    <div className="col-md-8">
                        <Box sx={{ width:'100%', p: 3, border: '1px dashed grey' }}> 
                        <form onSubmit={onSubmit} action="/create-vendor" method='POST'> 
                            <Stepper activeStep={activeStep}>
                                {steps.map((step, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                    return (
                                        <Step key={step} {...stepProps}>
                                            <StepLabel {...labelProps}>{step}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>

                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        Steps completed! Click the submit button
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) :  (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>{activeStep + 1}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                        >
                                        Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        
                                        <Button onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? <input className='form-control' style={{borderColor:'#220B21'}} type="submit" /> : 'Next'}
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            )}

                           
                                <FormControl fullWidth>   
                                    {activeStep === 0 ?
                                        <div>
                                            <TextField required
                                                onChange={(e)=>setVendor({...vendor, vendor_name:e.target.value.trim()})} placeholder='Vendor name' disabled
                                                id="outlined-basic" className='my-2 text-content-width'  name="vendor_name" label="Vendor name" variant="outlined" value={vendor.vendor_name} 
                                            /><br/>
                                            
                                            <TextField required
                                            onChange={(e)=>setVendor({...vendor, content_name:e.target.value})} placeholder='Content name..limit of 100 characters'
                                            id="outlined-basic" className='my-2 text-content-width'  name="movie_name" label="Content name" variant="outlined" value={vendor.content_name} 
                                            /> <br/>
    
                                            <TextField required disabled
                                                placeholder='Video name' onChange={(e)=>setVendor({...vendor, video_name:e.target.value})}
                                                id="outlined-basic" className='my-2 text-content-width'  name="video_name" label="Video name" variant="outlined" value={vendor.video_name} 
                                            /> <br/>

                                            <TextField 
                                                onChange={(e)=>setVendor({...vendor, content_description:e.target.value})}
                                                id="outlined-multiline-static"
                                                name="movie_description" 
                                                label="Movie description"
                                                multiline
                                                rows={4}
                                                className='my-2 text-content-width'
                                                value={vendor.content_description}
                                            /> <br/>

                                            {/* <TextField
                                                className='my-2 text-content-width'
                                                id="outlined-basic"
                                                select
                                                label="Genre"
                                                value={vendor.genre}
                                                onChange={(e)=>setVendor({...vendor, genre:e.target.value})}
                                                variant="outlined"
                                                >
                                                {Genres.map(genre => (
                                                    <MenuItem key={genre} value={genre}>
                                                        {genre}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}

                                            <FormControl variant="outlined" className="my-2 text-content-width">
                                                <InputLabel id="genre-label">Genre</InputLabel>
                                                <Select
                                                    labelId='genre-label'
                                                    className='text-content-width'
                                                    id="outlined-basic"
                                                    select
                                                    label="Genre"
                                                    multiple
                                                    value={vendor.genre}
                                                    onChange={handleGenreChange}
                                                    renderValue={(selected) => selected.join(', ')} 
                                                >
                                                    {Genres.map((genre) => (
                                                        <MenuItem key={genre} value={genre}>
                                                            <Checkbox checked={vendor.genre.indexOf(genre) > -1} />
                                                            <ListItemText primary={genre} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                className='my-2 text-content-width'
                                                id="outlined-basic"
                                                select
                                                label="Country"
                                                value={vendor.country}
                                                onChange={(e)=>setVendor({...vendor, country:e.target.value})}
                                                variant="outlined"
                                                >
                                                {Countries.map(country => (
                                                    <MenuItem key={country} value={country}>
                                                        {country}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <small>The theme or origin of the animation. e.g (Nigerian, Ghanian ,Egyptian)</small>

                                            {vendor.country == Countries[Countries.length - 1] ? 
                                                <TextField 
                                                    onChange={(e)=>setVendor({...vendor, other:e.target.value})}
                                                    id="outlined-multiline-static"
                                                    name="country_name" 
                                                    label="Country name"
                                                    className='my-2 text-content-width'
                                                    value={vendor.other}
                                                /> 
                                            : null} <br/>
                                        </div>
                                    : activeStep === 1 ?
                                        <div>
                                            <TextField
                                                required
                                                className='my-2 text-content-width'
                                                id="outlined-basic"
                                                select
                                                label="Content type"
                                                value={vendor.content_type}
                                                onChange={(e)=>setVendor({...vendor, content_type:e.target.value})}
                                                variant="outlined"
                                                >
                                                {ContentType.map(type => (
                                                    <MenuItem key={type} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            {vendor.content_type == ContentType[1] ? 
                                            <SeriesOptions onChange={(e)=>setVendor({...vendor, season_number:e.target.value})} seasonNumber={vendor.season_number}/> 
                                            : null} <br/>

                                            <TextField
                                                className='my-1 text-content-width'
                                                id="outlined-basic"
                                                select
                                                label="Video extension"
                                                value={vendor.video_extension}
                                                onChange={(e)=>setVendor({...vendor, video_extension:e.target.value})}
                                                variant="outlined"
                                                helperText='The preferred type is .mp4'
                                                >
                                                {VideoExtension.map(ext => (
                                                    <MenuItem key={ext} value={ext}>
                                                        {ext}
                                                    </MenuItem>
                                                ))}
                                            </TextField> <br/>

                                            {/* video path */}
                                            <TextField required disabled
                                                onChange={(e)=>setVendor({...vendor, video_path:e.target.value})} placeholder='Video path'
                                                id="outlined-basic" className='my-2 text-content-width'  name="video_path" label="Video path" variant="outlined" 
                                                value={
                                                    vendor.content_type == ContentType[0] || vendor.content_type == ContentType[2] ? `${vendor.vendor_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}/${vendor.content_type}/${vendor.content_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}`: 
                                                    `${vendor.vendor_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}/${vendor.content_type}/${vendor.content_name.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-').trim()}/Season${vendor.season_number}`
                                                } 
                                            /> <br/>

                                            <TextField required inputProps={{inputMode: 'numeric', pattern: '[0-9]*' }}
                                                onChange={(e)=>setVendor({...vendor, release_year:e.target.value})} placeholder='Release year'
                                                id="outlined-basic" className='my-2 text-content-width'  name="release_year" label="Release year" variant="outlined" 
                                                value={vendor.release_year} 
                                                helperText='e.g 2021, 2022, 2023'
                                            /> <br/>

                                            <TextField required
                                                helperText='Add up to 6 actors, press enter'
                                                onChange={(e) => setActorVal(e.target.value)} 
                                                onKeyDown={setActors}
                                                placeholder='Actors'
                                                id="outlined-basic" className='my-2 text-content-width'  name="video_actors" label="Actors" variant="outlined" 
                                                value={actorVal}
                                            />

                                            <div className="row">
                                                    {vendor.actors.map((actor, index)=>{
                                                        return <div key={index} className="col-md-2">
                                                            <Button onClick={()=>{
                                                                vendor.actors.splice(index, 1)
                                                                setVendor({...vendor, actors:[...vendor.actors]})
                                                            }} 
                                                            key={index} size="small" variant="outlined" color='error' className='delete-btn mt-1' 
                                                            endIcon={<DeleteIcon />}
                                                            >
                                                                {actor}
                                                            </Button>
                                                        </div>
                                                    })}
                                            </div>

                                            {/*Animators*/}
                                            <TextField required
                                                helperText='Add animators, press enter'
                                                onChange={(e) => setAnimatorVal(e.target.value)} 
                                                onKeyDown={setAnimators}
                                                placeholder='Animators'
                                                id="outlined-basic" className='my-2 text-content-width'  name="video_animators" label="Animators" variant="outlined" 
                                                value={animatorVal}
                                            />

                                            <div className="row">
                                                    {vendor.animators.map((animator, index)=>{
                                                        return <div key={index} className="col-md-2">
                                                            <Button onClick={()=>{ vendor.animators.splice(index, 1)
                                                                setVendor({...vendor, animators:[...vendor.animators]})
                                                            }} 
                                                                key={index} size="small" 
                                                                variant="outlined" color='error' className='delete-btn mt-1' 
                                                                endIcon={<DeleteIcon />}
                                                            >
                                                                {animator}
                                                            </Button>
                                                        </div>
                                                    })}
                                            </div>
                                        </div>
                                    : 
                                        <div>
                                            <Box sx={{ p: 2, marginY:2, border: '1px dashed black' }}>
                                                <p>Video's <span style={{fontSize:'12px', fontWeight:'bold',  color:'red'}}>(Max 2GB)</span></p>
                                                <input onChange={handleFileInputChange} className="file_input" 
                                                    type="file" encType="multipart/form-data" 
                                                    name="video_name" 
                                                    id="file" 
                                                    data-multiple-caption="{count} files selected" 
                                                    multiple 
                                                />
                                                {loading.vidProgress ? 
                                                <div> 
                                                    <CircularProgress
                                                        size={15}
                                                        sx={{
                                                        color: green[500],
                                                        zIndex: 1,
                                                        }}
                                                    /> 
                                                </div>:null}
                                                {loading.vidSuccess ? <CheckIcon color='success' fontSize='medium'/> : null}
                                            </Box>

                                            <Box sx={{ p: 2, marginY:2, border: '1px dashed black' }}>
                                                <p>Subtitles your <b>.vtt</b> file <span style={{fontSize:'12px', fontWeight:'bold', color:'red'}}>(Max 3MB)</span></p>
                                                <span>Please follow the pattern. (<Link target={'_blank'} to='/help'>language</Link>.contentname.extension). for your file name</span>
                                                <p>Example</p>
                                                <p>English would be <span style={{textDecoration:'underline'}}>(en.Lionking.vtt)</span> Or Yoruba would be <span style={{textDecoration:'underline'}}>(yb.Lionking.vtt)</span></p>
                                                {
                                                    SubtitleLanguages.map((lang, idx) =>{
                                                        return <div className='d-flex'>
                                                            <TextField key={lang.language}
                                                                className='my-2 text-content-width'
                                                                id="outlined-basic"
                                                                select
                                                                label="Subtitle language"
                                                                value={vendor.subtitles_language[idx]}
                                                                onChange={(e)=>{
                                                                    const languages = [...vendor.subtitles_language];
                                                                    if (e.target.value === "") {
                                                                        languages.splice(idx, 1)
                                                                    } else {
                                                                        languages[idx] = e.target.value;
                                                                    }
                                                                    setVendor({...vendor, subtitles_language:languages})
                                                                }}
                                                                variant="outlined">
    
                                                                <MenuItem value="">Clear</MenuItem>
                                                                {SubtitleLanguages.map(lang => (
                                                                    <MenuItem key={lang.language} value={lang.language}>
                                                                        {lang.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>

                                                            <input onChange={handleSubtitleFileInputChange} className="file_input mt-4 ms-2" 
                                                                type="file" encType="multipart/form-data" 
                                                                name="video_subtitles" 
                                                                id={`file-${lang.label}`}
                                                                data-multiple-caption="{count} files selected" 
                                                                multiple 
                                                            />
                                                        </div>
                                                    })
                                                }
                                                {/* <input onChange={handleSubtitleFileInputChange} className="file_input mt-4 ms-2" 
                                                        type="file" encType="multipart/form-data" 
                                                        name="video_subtitles" 
                                                        id="file" 
                                                        data-multiple-caption="{count} files selected" 
                                                        multiple 
                                                    /> */}
                                                {loading.subProgress ? 
                                                <div> 
                                                    <CircularProgress
                                                        size={15}
                                                        sx={{
                                                        color: green[500],
                                                        zIndex: 1,
                                                        }}
                                                    /> 
                                                </div>:null}
                                                {loading.subSuccess ? <CheckIcon color='success' fontSize='medium'/> : null}
                                            </Box>

                                            <Box sx={{ p: 2, marginY:2, border: '1px solid black' }}>
                                                <p>Video images  
                                                    <span style={{fontSize:'12px', fontWeight:'bold', color:'red'}}><br/>Swipe Image:(Max 3MB). Header Image:(Max 4MB)</span>
                                                </p>
                                                
                                                <div className="d-flex">
                                                    <Box sx={{ p: 4, marginY:2, marginX: 1, border: '1px dashed grey' }}>
                                                        <p>Swipe card video image</p>
                                                        <input onChange={handleImageFileInputChange} className="file_input" 
                                                            type="file" encType="multipart/form-data" 
                                                            name="video_image" 
                                                            id="file" 
                                                            data-multiple-caption="{count} files selected" 
                                                            multiple 
                                                        />
                                                        {loading.imgProgress ? 
                                                        <div> 
                                                            <CircularProgress
                                                                size={15}
                                                                sx={{
                                                                color: green[500],
                                                                zIndex: 1,
                                                                }}
                                                            /> 
                                                        </div>:null}
                                                        {loading.imgSuccess ? <CheckIcon color='success' fontSize='medium'/> : null}
                                                    </Box>
                                                
                                                    <Box sx={{ p: 4, marginY:2, marginX: 1, border: '1px dashed gray' }}>
                                                        <p>Header video image <span style={{fontSize:'14px', fontWeight:'bold',  color:'red'}}>(W:1400 H:672)</span></p>
                                                        <input onChange={handleHeaderImageFileInputChange} className="file_input" 
                                                            type="file" encType="multipart/form-data" 
                                                            name="video_image_header" 
                                                            id="file" 
                                                            data-multiple-caption="{count} files selected" 
                                                            multiple 
                                                        />
                                                        {loading.hdrImgProgress ? 
                                                        <div> 
                                                            <CircularProgress
                                                                size={15}
                                                                sx={{
                                                                color: green[500],
                                                                zIndex: 1,
                                                                }}
                                                            /> 
                                                        </div>:null}
                                                        {loading.hdrImgSuccess ? <CheckIcon color='success' fontSize='medium'/> : null}
                                                    </Box>
                                                </div>

                                            </Box>
                                            <input className={`form-control ${submitBtn}`} style={{borderColor:'#220B21'}} type="submit" />
                                            {loading.vidProgress ? 
                                                <div className='mt-2'> 
                                                    <p style={{color:'#220b21',fontWeight:300}}>Uploading files...do not refresh</p>
                                                    <CircularProgress
                                                        size={30}
                                                        sx={{
                                                        color: green[500],
                                                        zIndex: 1,
                                                        }}
                                                    /> 
                                            </div>:null}
                                            {loading.vidSuccess ? <CheckIcon className='mt-2' color='success' fontSize='large'/> : null}
                                        </div>
                                    }
                                </FormControl>
                            </form>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard