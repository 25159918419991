import React, { useState, useEffect } from "react";
import { IconButton, MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const daysOptions = Array.from({ length: 6 }, (_, i) => (i + 1) * 5);
const DaysPickerComponent = ({ setSelectedDays }) => {
	const [days, setDays] = useState('');

	useEffect(() => {
		setSelectedDays(days);
	}, [days, setSelectedDays]);

	const handleDaysChange = (event) => {
		setDays(event.target.value);
	};

	const handleClear = () => {
		setDays('');
		setSelectedDays('');
	};

  return (
    <Box display="flex" alignItems="center" width="100%">
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Select Days</InputLabel>
        <Select
          sx={{height:'50px'}}
          value={days}
          onChange={handleDaysChange}
          label="Select Days"
          renderValue={(selected) => selected || ''}
        >
          {daysOptions.map(day => (
            <MenuItem key={day} value={day}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {days && (
        <IconButton
          onClick={handleClear}
          edge="end"
          aria-label="clear days"
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default DaysPickerComponent;
