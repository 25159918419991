import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { BiLoaderCircle } from 'react-icons/bi';
import { FiUploadCloud } from 'react-icons/fi';
import { Box, Button, Typography, List, ListItem, ListItemText, TextField, Grid } from '@mui/material';

const Documents = () => {
    const [documents, setDocuments] = useState([]);
    const [newDocument, setNewDocument] = useState(null);
    const [documentName, setDocumentName] = useState('');
    const [loading, setLoading] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const previewUrlRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
        setLoading(true);
        const file = acceptedFiles[0];
        if (file) {
            setNewDocument(file);
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
            previewUrlRef.current = objectUrl;
        } else {
            setNewDocument(null);
            setPreviewUrl(null);
            previewUrlRef.current = null;
        }
        setLoading(false);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop,
    });

    const handleUpload = () => {
        if (newDocument && documentName.trim()) {
            const url = URL.createObjectURL(newDocument);
            setDocuments([...documents, { name: documentName, url }]);
            setDocumentName('');
            setNewDocument(null);
            setPreviewUrl(null);
            if (previewUrlRef.current) {
                URL.revokeObjectURL(previewUrlRef.current);
            }
        }
    };

    useEffect(() => {
        if (newDocument) {
            const objectUrl = URL.createObjectURL(newDocument);
            setPreviewUrl(objectUrl);
            previewUrlRef.current = objectUrl;
        } else {
            setPreviewUrl(null);
            previewUrlRef.current = null;
        }

        return () => {
            if (previewUrlRef.current) {
                URL.revokeObjectURL(previewUrlRef.current);
            }
        };
    }, [newDocument]);

    return (
        <Box>
           <List>
                {documents.map((doc, index) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ListItemText primary={doc.name} />
                        <Button 
                            variant="contained" 
                            color="success" 
                            component="a" 
                            href={doc.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            View Document
                        </Button>
                    </ListItem>
                ))}
            </List>
            <TextField
                label="Document Name"
                variant="outlined"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <Box
                    sx={{
                        border: '2px dashed gray',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        mb: 2
                    }}
                >
                    <FiUploadCloud style={{ fontSize: '3rem', color: 'gray' }} />
                    <Typography>Drag your document here</Typography>
                    <Typography variant="caption" color="textSecondary">
                        (Only *.pdf, *.doc, and *.docx files will be accepted)
                    </Typography>
                </Box>
            </div>
            
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px dashed gray', borderRadius: '8px', height: '100px' }}>
                    <BiLoaderCircle className="animate-spin" style={{ fontSize: '3rem', color: 'gray' }} />
                    <Typography variant="body2">Uploading...</Typography>
                </Box>
            ) : (
                previewUrl && (
                    <Box sx={{ border: '2px dashed gray', borderRadius: '8px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="body2">{newDocument.name}</Typography>
                    </Box>
                )
            )}
            <Button variant="contained" style={{background:'#2D132C'}} onClick={handleUpload} fullWidth>
                Upload Document
            </Button>

        </Box>
    );
};

export default Documents;
