import React, { useState, useEffect } from "react";
import { Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material";
import SideNav from '../Dashboard/NavSide'
import { instance } from "../../axiosConfig";

const FailingUploads = ({ vendorId }) => {
    const [failingUploads, setFailingUploads] = useState([]);

    useEffect(() => {
        const fetchFailingUploads = async () => {
            const response = await instance.get(`/failing-uploads/${vendorId}`);
            setFailingUploads(response.data.items);
        };
        fetchFailingUploads();
    }, [vendorId]);
    
    return (
        <>
            <SideNav/>
            <div className="container">
                <h4 className="mt-3">Failing Uploads</h4>
                <div className="row mt-3 justify-content-center">
                    <div className="col-md-10">
                        <Table sx={{ minWidth: 650, background:'#2D132C', color:'white', borderRadius:'5px' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{color:'white'}} sx={{fontWeight:'bold'}}>Content Id</TableCell>
                                    <TableCell style={{color:'white'}} sx={{fontWeight:'bold'}}>Error</TableCell>
                                    <TableCell style={{color:'white'}} sx={{fontWeight:'bold'}}>Path</TableCell>
                                    <TableCell style={{color:'white'}} sx={{fontWeight:'bold'}}>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                {failingUploads && failingUploads.map((upload) => (
                                    <TableRow key={upload.item}>
                                        <TableCell style={{color:'white'}}>{upload.item}</TableCell>
                                        <TableCell style={{color:'white'}}>{upload.error_code}</TableCell>
                                        <TableCell style={{color:'white'}}>{upload.path}</TableCell>
                                        <TableCell style={{color:'white'}}>{new Date(upload.date).toUTCString()|| 'N/A'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <small className="mt-4">Clears 48 hours after failure</small>
                    </div>
                </div>
            </div>
        </>
    )
}   

export default FailingUploads;