import React from 'react'
import { useNavigate } from 'react-router-dom'
import NavSide from '../Dashboard/NavSide'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import {SubtitleLanguages} from '../../constants'
import "./Help.css"
import { Button } from '@mui/material'

const Help =()=>{
    const navigate = useNavigate()

    const onBackPressed = () => {
		navigate(-1);
	}

    return(
        <div>
            <NavSide/>
            <div className='accordions'>
                <h2>Video Questions</h2>
                <Accordion>
                    <AccordionSummary>
                        <Typography>What file types are accepted?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            mp4, avi, mov, wmv, flv, mkv, webm, mpeg, mpg, 3gp, m4v
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>
                        <Typography>What is the maximum file size?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            1 GB
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>
                        <Typography>What is the minimum video resolution?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            720p
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>
                        <Typography>What is the minimum video frame rate?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            30 fps
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>
                        <Typography>What is the required video aspect ratio?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            16:9
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <h2>Image Questions</h2>
                <Accordion>
                    <AccordionSummary className='border-bottom'>
                        <Typography>What file types are accepted?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            png, jpg, jpeg
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary className='border-bottom'>
                        <Typography>What is the maximum file size?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            3 MB
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary className='border-bottom'>
                        <Typography>What is the minimum image quality?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            300 PPI
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <h2>Subtitle Questions</h2>
                <Accordion>
                    <AccordionSummary className='border-bottom'>
                        <Typography>What file types are accepted?</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            vtt
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary className='border-bottom'>
                        <Typography>What is the maximum file size?</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            3 MB
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary className='border-bottom'>
                        <Typography>What languages are required?</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            The native language of the content and English
                        </Typography>

                        <Typography>We currently support 
                            {SubtitleLanguages.map(lang=>{
                                return <li className='d-flex'><b>{lang.label} :<span style={{color:'red'}}>{lang.language}</span></b></li>
                            })}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default Help;
