import { Container, Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { Link, useLocation, useNavigate} from 'react-router-dom'
import MovieCard from '../Movies/Cards'
import NavSide from '../Dashboard/NavSide'

const AdminVendorContent =(props)=>{
    const location = useLocation();
    const { content } = location.state || {}
    const { role } = location.state || ''

    useEffect(()=>{
        console.log(content)
    },[])

    return(
        <>
            <NavSide/>
            <div className="container">
                <div className="row justify-content-center mt-2">
                    <div className="col-md-12">
                        <h1>Content</h1>
                    </div>
                </div>
            </div>

            <Container className="movie-card-container" maxWidth='md'>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                    { content && content.length > 0 ? content.map((movie, index)=>{
                        return <Grid item xs={2} sm={4} md={3} key={index}>
                                <MovieCard key={movie.content_id} id={movie.content_id} movieName={movie.content_name} duration={movie.duration} licensedFrom={movie.day_uploaded} hoursWatched={movie.hoursStreamed} 
                                    trending={movie.isTrending ? 'Yes' : 'No'} genre={movie.genre} releaseYear={movie.released} description={movie.content_description} role={role}/>
                            </Grid>
                    }) : <div className="text-center" style={{marginLeft:'200px', 
                    background:'#2D132C', 
                    border:'1px solid #C170C1',
                    borderRadius:'10px',
                    padding:'10px',
                    width:'100%',
                    fontWeight:'bold',
                    color:'white'
                    }}>No content yet for vendor.</div>}
                </Grid>
            </Container>
        </>
    )
}

export default AdminVendorContent