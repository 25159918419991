import React, { useEffect,useState } from 'react'
import { useLocation } from 'react-router-dom'
import {instance} from '../../axiosConfig'
import SearchBar from  '../Movies/Search/Search'
import NavSide from '../Dashboard/NavSide'
import { Container, Grid } from '@mui/material';
import Typography  from '@mui/material/Typography';
import { BarChart  } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import './Activity.css'

const Activiy=()=>{
    const [creatorViews, setCreatorViews] = useState()
    const [colorScheme, setColorScheme] = useState('Paired');

    const creatorId = localStorage.getItem('userId')

    useEffect(()=>{
        getCreatorViews()
    },[])

    const getCreatorViews = async()=>{
        const res = await instance.get(`/${creatorId}/metrics/creator/views`)
        if(res.data.status === 200){
            const {data} = res.data
            console.log(data)
            setCreatorViews(data)
            //Object.values(test).flatMap(arr => arr.map(obj => obj.name));
        }
    }

    const getViewStartDate=(time)=>{
        //Convert Timestamp to milliseconds
        let startDate = new Date(time * 1000).toUTCString()
        return startDate;
    }

    const getViewEndDate=(time)=>{
        let endDate = new Date(time * 1000).toUTCString()
        return endDate
    }

    const convertLengthMilliToTime = (length)=>{
		const totalMinutes = Math.floor(length / 60000); // 1 minute = 60,000 milliseconds
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		if(!isNaN(hours) && !isNaN(minutes)){
			return { hours, minutes };
		}
		return {hours:0, minutes:0}
	}

    const categories={
        Paired: [
            '#a6cee3',
            '#1f78b4',
            '#b2df8a',
            '#33a02c',
            '#fb9a99',
            '#e31a1c',
            '#fdbf6f',
            '#ff7f00',
            '#cab2d6',
            '#6a3d9a',
            '#ffff99',
            '#b15928',
        ],
    }

    return(
        <div>
            <SearchBar searchInput=''/>
            <NavSide/>
            {/* Time frame: we should be able to select a time frame and send it to the backend as query params */}
            <div style={{fontWeight:'bold', marginTop:'80px'}}>Time period (30 days)</div>
            <div>{`${getViewStartDate(creatorViews && creatorViews.views.timeframe[0])} - ${getViewEndDate(creatorViews && creatorViews.views.timeframe[1])}`}</div>
            {/*  */}

            <Grid container justifyContent="center" marginTop={10} rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                <Grid item xs={2} sm={4} md={3} style={{paddingLeft:'150px'}} >
                    <div className='chart-skeleton'>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['2024', '2025', '2026','2027'], label:'Years'}]} 
                            yAxis={[{ label:'Views'}]}
                            series={[
                                { 
                                    id: 0, 
                                    label: 'Total content views', 
                                    data: [creatorViews && creatorViews.views.total_row_count, 0, 0],
                                    color:'#fb9a99'
                                }, 
                               
                            ]}
                            colors={categories[colorScheme]}
                            width={360}
                            height={220}
                        />
                    </div>
                </Grid>

                <Grid item xs={2} sm={4} md={3} style={{paddingLeft:'150px'}} >
                    <div className='chart-skeleton'>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: ['2024', '2025', '2026','2027'], label:'Content'}]} 
                        yAxis={[{ label:'Playing time (minutes)'}]}
                        series={[
                            { 
                                id: 0, 
                                label: 'Minutes', 
                                data: [creatorViews && convertLengthMilliToTime(creatorViews.totalPlayingTime).minutes] 
                            }, 
                        ]}
                        width={360}
                        height={220}
                        />
                    </div>
                </Grid>

                <Grid item xs={2} sm={4} md={3} style={{paddingLeft:'150px'}} >
                    <div className='chart-skeleton'>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['2024', '2025', '2026','2027'], label:'Content'}]} 
                            yAxis={[{ label:'Unique Views'}]}
                            series={[
                                { id: 0, label: 'Unique views', data: [creatorViews && creatorViews.uniqueViews, 0, 0] }, 
                            ]}
                            width={360}
                            height={220}
                        />
                    </div>
                </Grid>
            </Grid>

            {/* ROW TWO */}
            <Grid container justifyContent="center" marginTop={10} rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                {/* <Grid item xs={2} sm={4} md={3} marginLeft={5} >
                    <div className=''>
                        <BarChart
                            series={[
                                { data: [35, 44, 24, 34] },
                                { data: [51, 6, 49, 30] },
                                { data: [15, 25, 30, 50] },
                                { data: [60, 50, 15, 25] },
                            ]}
                            width={400}
                            height={220}
                            xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
                            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                            />
                    </div>
                </Grid> */}

                <Grid item xs={2} sm={4} md={3} style={{paddingLeft:'150px'}} >
                    <div className='chart-skeleton'>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: creatorViews && creatorViews.viewsByCountry ? Object.keys(creatorViews.viewsByCountry) : [], label:'Country'}]} 
                            yAxis={[{ label:'Views'}]}
                            series={[
                                { 
                                    id: 0, 
                                    label: 'Views', 
                                    data: [creatorViews && Object.keys(creatorViews.viewsByCountry).map(countryCode =>  creatorViews.viewsByCountry[countryCode].length)] 
                                }, 
                            ]}
                            width={360}
                            height={220}
                        />
                    </div>
                </Grid>

                <Grid item xs={2} sm={3} md={3} style={{paddingLeft:'150px'}} >
                    <div className='chart-skeleton-pie' style={{padding:'10px'}}>
                        <Typography>Views Per Quarter</Typography>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { 
                                            id: 0,
                                            value: creatorViews && creatorViews.quarterlyViews ? creatorViews.quarterlyViews["1"].views : 0, 
                                            label: 'Q1',
                                            color:'lightgreen'
                                        },
                                        { 
                                            id: 1, 
                                            value: creatorViews && creatorViews.quarterlyViews ? creatorViews.quarterlyViews["2"].views : 0, 
                                            label: 'Q2',
                                            color:'pink'
                                        },
                                        { 
                                            id: 2, 
                                            value: creatorViews && creatorViews.quarterlyViews ? creatorViews.quarterlyViews["3"].views : 0, 
                                            label: `Q3`,
                                            color:'#15C690'
                                        },
                                        { 
                                            id: 3, 
                                            value: creatorViews && creatorViews.quarterlyViews ? creatorViews.quarterlyViews["4"].views : 0, 
                                            label: `Q4`,
                                            color:'#15C690'
                                        },
                                    ],
                                    arcLabel: (item) => `${item.value}`,
                                    arcLabelMinAngle: 10,
                                    innerRadius: 50,
                                    paddingAngle: 3,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                },
                            ]}
                            width={360}
                            height={175}
                        />
                    </div>
                </Grid>

                <Grid item xs={2} sm={4} md={3} style={{paddingLeft:'150px'}} >
                    <div className='chart-skeleton'>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['Windows', 'Android', 'iOS', 'MacOs'], label:'Device'}]} 
                            yAxis={[{ label:'Views'}]}
                            series={[
                                { 
                                    id: 0, 
                                    label: 'Views',
                                    data: [
                                        creatorViews && creatorViews.viewsByWindows.length, 
                                        creatorViews &&  creatorViews.viewsByAndroid.length,
                                        creatorViews &&  creatorViews.viewsByiOS.length,
                                        creatorViews && creatorViews.viewsByMacOs.length,
                                    ] 
                                }, 
                            ]}
                            width={360}
                            height={220}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Activiy