import React, { useState, useEffect } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import ClearIcon from '@mui/icons-material/Clear';

const DateRangePickerComponent = ({ setSelectedDate }) => {
  	const [value, setValue] = useState([null, null]);

	useEffect(() => {
		if (value[0] && value[1]) {
			setSelectedDate({ startDate: value[0].toDate(), endDate: value[1].toDate() });
		}
	}, [value, setSelectedDate]);

	const handleClear = () => {
		setValue([null, null]);
		setSelectedDate(null);
	};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker startText="Start Date" endText="End Date"
        	value={value}
        	onChange={(newValue) => setValue(newValue)}
			renderInput={(startProps, endProps) => (
			<>
				<TextField
					sx={{height:'50px'}}
					{...startProps}
					InputProps={{...startProps.InputProps,
						endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={handleClear} edge="end">
								{value[0] || value[1] ? <ClearIcon /> : <startProps.InputProps.endAdornment.props.children />}
							</IconButton>
						</InputAdornment>
						),
					}}
				variant="outlined"
				size="medium"
				/>
			
				<TextField
				{...endProps}
				InputProps={{
					...endProps.InputProps,
					endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={handleClear} edge="end">
						{value[0] || value[1] ? <ClearIcon /> : <endProps.InputProps.endAdornment.props.children />}
						</IconButton>
					</InputAdornment>
					),
				}}
				sx={{height:'50px'}}
				variant="outlined"
				size="medium"
				/>
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateRangePickerComponent;
