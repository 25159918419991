import React from "react";
import { Link } from 'react-router-dom'
import './MovieCard.css'

const MovieCard = (props)=>{
    const date = new Date()
    const role = props.role

    return (
        <div key={props.movieName}>
            <Link style={{textDecoration:'none'}} to={`/movie/${props.id}/info`} state={{
                id: props.id,
                movieName: props.movieName,
                licensedDate: props.licensedFrom,
                amountOfHoursWatched: props.hoursWatched,
                isTrending: props.trending,
                description: props.description,
                year:props.releaseYear,
                genre:props.genre
            }}>
                <div className={`card ${role === 'admin' ? 'movie-card-admin' : 'movie-card'}`}>
                    <div className="card-body">
                        {/* <img src="" alt="movie-image" /> */}
                        <div className="card-title mb-3" style={{fontWeight:'bold'}}>{`Content name: ${props.movieName}`}</div>
                        <span className="text-left mt-2">
                            <p>{`Date added: ${props.licensedFrom}`}</p>
                            {/* <p>{`Duration of contract: ${date.toUTCString()}`}</p> */}
                            <p>Licensed from:{props.licensedFrom}</p>
                            {/* <p>Hours watched:{props.hoursWatched}</p> */}
                            <p>Trending:{props.trending}</p>
                        </span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default MovieCard;