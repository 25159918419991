import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import {Route, Routes} from 'react-router'
import Home from './Home/Home'
import Dashboard from './Dashboard/Dashboard'
import Movies from './Movies/Movies'
import AdminContentView from './Admin/AdminContentView'
import AdminVendorContent from './Admin/AdminVendorContent'
import MovieDetails from './MovieDetails/MovieDetail'
import Activity from './Statistics/Activity'
import Help from './Help/Help'
import Account from './Account/Account'
import Independent from './Authentication/Independent/Independent'
import FailingUploads from './FailingEntities/FailingUploads';
import AuthContextProvider from '../context/AuthContext'
import './App.css';


function App() {
	const userId = 	localStorage.getItem('userId')

	return (
		<Router>
			<AuthContextProvider>
				<div className="App">
					<Routes>
						<Route path="/"  element={<Home/>}/>
						<Route path="/independent"  element={<Independent/>}/>
						<Route path="/login"  element={<Home/>}/>
						<Route path="/console"  element={<Dashboard/>}/>
						<Route path="/account"  element={<Account/>}/>
						<Route path="/activity"  element={<Activity/>}/>
						<Route path="/content"  element={<Movies/>}/>
						<Route exact path="/content/admin"  element={<AdminContentView/>}/>
						<Route path="/admin/content/:vendor"  element={<AdminVendorContent/>}/>
						<Route path="/help" element={<Help/>}/>
						<Route path="/failing" element={<FailingUploads vendorId={userId}/>}/>
						<Route exact path="/movie/:id/info"  element={<MovieDetails/>}/>
					</Routes>
				</div>
			</AuthContextProvider>
		</Router>
  );
}
export default App;
